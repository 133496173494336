.login_top{
  padding: 60px 0 54px 0;
}
.login_input{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  padding: 10px 5px 10px 10px;
  background: rgba(243, 247, 249, 1);
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
}
.login_input:focus{
  outline: none;
}
.input_field_margin{
  margin-bottom: 24px;
}
.login_button{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  padding: 15px 0 15px 0;
  width: 100%;
}
.login_button_log{
  background: #B01E3C;
  text-align: center;
  margin:70px 0 70px 0;
  border-radius: 2px;
}

/* new order details vs-work */
/* admin-panel */
.admin-panel {
  display: grid;
  grid-template-areas: "sidebar main";
  grid-template-columns: 110px auto;
  grid-template-rows: auto;
  min-height: 100%;
}
.admin-panel.with-dashboard {
  height: 100%;
}
.admin-panel.with-dashboard main {
  height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.admin-panel.with-dashboard main header {
  padding: 20px 20px 0;
  margin-bottom: 0;
  flex: 0 0 auto;
}
.admin-panel.with-dashboard main section {
  overflow: auto;
  padding: 20px;
  flex: 1 1 auto;
}
.admin-panel.with-dashboard .card {
  display: flex;
  flex-direction: column;
}
.admin-panel.with-dashboard .card .card-header {
  flex: 0 0 auto;
}
.admin-panel.with-dashboard .card .card-header + .card-content {
  overflow: auto;
}
.admin-panel.with-dashboard .card .card-content {
  flex: 1 1 auto;
}
.admin-panel aside {
  grid-area: sidebar;
}
.admin-panel aside nav {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: auto;
}
.admin-panel main {
  grid-area: main;
  padding: 20px;
  background-color: #5f6163;
  max-width: calc(100vw - 127px);
}
section {
  padding-bottom: 20px;
}
/* dashboard vs-work */

.dashboard {
  gap: 13px 11px;
}
@media (min-width: 1100px) {
  .dashboard {
    display: grid;
 }
}
@media (max-width: 1099px) {
  .dashboard {
    display: flex;
    flex-direction: column;
 }
}
.dashboard .card-content {
  padding: 14px;
}
.dashboard.dashboard-order {
  padding: 15px;
  grid-template-columns: 2fr 0.3fr 1fr 0.3fr minmax(1.5fr, 290px);
  grid-template-rows: auto minmax(auto, 1fr) 1fr auto;
  grid-template-areas: "order location location location status" "items items history customer-details customer-details" "items items history courier-details courier-details" "items items evaluation-details evaluation-details evaluation-details";
}
.dashboard.dashboard-order .card.order {
  grid-area: order;
}
.dashboard.dashboard-order .card.items {
  grid-area: items;
}
.dashboard.dashboard-order .card.location {
  grid-area: location;
}
.dashboard.dashboard-order .card.status {
  grid-area: status;
}
.dashboard.dashboard-order .card.history {
  grid-area: history;
}
.dashboard.dashboard-order .card.customer-details {
  grid-area: customer-details;
}
.dashboard.dashboard-order .card.courier-details {
  grid-area: courier-details;
}
.dashboard.dashboard-order .card.evaluation-details {
  grid-area: evaluation-details;
}
.dashboard.dashboard-order .card.history, .dashboard.dashboard-order .card.items {
  overflow: auto;
}
.dashboard.dashboard-evaluation {
  grid-template-columns: 1fr 0.5fr 0.1fr 0.5fr;
  grid-template-rows: auto auto auto auto auto 0.5fr;
  grid-template-areas: "details details details details" "items order order status" "items address address address" "items history customer-details customer-details" "items history courier-details courier-details" "items . . .";
}
.dashboard.dashboard-evaluation .card.details {
  grid-area: details;
}
.dashboard.dashboard-evaluation .card.items {
  grid-area: items;
}
.dashboard.dashboard-evaluation .card.order {
  grid-area: order;
}
.dashboard.dashboard-evaluation .card.status {
  grid-area: status;
}
.dashboard.dashboard-evaluation .card.address {
  grid-area: address;
}
.dashboard.dashboard-evaluation .card.history {
  grid-area: history;
}
.dashboard.dashboard-evaluation .card.customer-details {
  grid-area: customer-details;
}
.dashboard.dashboard-evaluation .card.courier-details {
  grid-area: courier-details;
}
.dashboard.dashboard-evaluation .card.history, .dashboard.dashboard-evaluation .card.items {
  overflow: auto;
}
.dashboard.dashboard-home {
  grid-template-columns: 1fr 1fr 0.1fr 1fr;
  grid-template-rows: auto auto auto auto auto 1fr 1fr minmax(1fr, 50vh);
  grid-template-areas: "top-panel top-panel top-panel top-panel" "chart chart chart status-success" "chart chart chart status-faild" "chart chart chart sales" "chart chart chart payment" "orders orders orders orders" "top-sales evaluation popular popular" "top-sales evaluation popular popular";
}
.dashboard.dashboard-home .card.top-panel {
  grid-area: top-panel;
}
.dashboard.dashboard-home .card.status-success {
  grid-area: status-success;
}
.dashboard.dashboard-home .card.status-faild {
  grid-area: status-faild;
}
.status-eta {
  color: #4a4a4a;
  font-size: 15px;
}
.dashboard.dashboard-home .card.chart {
  grid-area: chart;
  display: flex;
  flex-direction: column;
}
.dashboard.dashboard-home .card.chart .card-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.dashboard.dashboard-home .card.chart .card-content #sales-chart {
  flex: 1 1 auto;
  height: 100%;
}
.dashboard.dashboard-home .card.chart .card-header {
  flex: 0 0 auto;
}
.dashboard.dashboard-home .card.sales {
  grid-area: sales;
}
.dashboard.dashboard-home .card.payment {
  grid-area: payment;
}
.dashboard.dashboard-home .card.orders {
  grid-area: orders;
}
.dashboard.dashboard-home .card.top-sales {
  grid-area: top-sales;
}
.dashboard.dashboard-home .card.evaluation {
  grid-area: evaluation;
}
.dashboard.dashboard-home .card.popular {
  grid-area: popular;
}
.dashboard.dashboard-home .card .overflow-auto, .dashboard.dashboard-home .card .overflow-scroll {
  max-height: 50vh;
  overflow: auto;
}

/* card vs-work */
.card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 0.5em 1em -0.125em rgba(15,27,26,0.1), 0 0px 0 1px rgba(15,27,26,0.02);
  color: #4a4a4a;
  max-width: 100%;
  position: relative;
}

.card.items {
  overflow: auto;
}

.card > .columns > .column:not(:first-child) > .card-content {
  border-left: 1px solid #f3f7f9;
}
.card > .tabs {
  padding-top: 10px;
}
.card .card-header {
  padding: 15px;
  box-shadow: none;
  border-bottom: 1px solid #f3f7f9;
  display: flex;
  align-items: center;
}
.card .card-header .card-actions {
  padding-right: 15px;
}
.card .card-header .card-actions > *:not(:last-child) {
  padding-right: 15px;
}
.card .card-header-title {
  font-weight: 400;
  font-size: 18px;
}
.card > .card-content {
  padding: 15px 15px;
}
.card > .card-content > nav.tabs {
  margin: 0 -12px;
  padding-top: 10px;
}
.card > .card-content + .card-content {
  border-top: 1px solid #f3f7f9;
}
.card > .card-content.outputs {
  padding: 23px;
  position: relative;
}
.card > .card-content.outputs .card-actions {
  top: 26px;
  right: 24px;
  position: absolute;
}
.card > .card-content:not(.outputs):only-child {
  padding: 10px 12px;
}
.card > .card-content.normal {
  padding: 0.75rem 1rem;
}
.card > .card-content .box {
  box-shadow: none;
  padding: 8px;
}
.card > .card-content .card-header.is-parent {
  padding: 0;
  border: 0 none;
}
.card .table {
  border-radius: inherit;
}
.card .card-footer {
  padding: 10px 12px;
}
.card .card-content h4 {
  margin: 10px 0 20px;
}
.card-content.scroll {
  max-height: calc(100vh - var(--nav-height, 156px));
  overflow: auto;
}

/* vs finish */

.header_top{
  width: 110px;
  background: #B01E3C;
}
.header_inner_1{
  padding-bottom: 10px;
  padding-top: 14px;
}
.header_top_inner{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  opacity: 0.5;
}
.header_top_inner1{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  opacity: 0.5;
}
.header_menu_text{
  padding-top: 10px;
}

a.header_top_inner.active{
  color: #ffffff;
  opacity: 1;
}
.header_nav{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



.Order_top_class{
  margin-left: 112px;
}
.Order_top_inner_text{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #0F1B1A;
}
.Order_top_inner{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 20px;
  background: #F6F7FB;
  padding: 6px 0px 7px 10px;
}
.Order_top_inner1{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 20px;
  background: #FFF500;
  padding: 11px 10px 11px 7px;
  border-radius: 4px;
}

.Order_top_inner2{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 4px;
  background: #F44336;
  padding: 12px 12px 12px 7px;
  border-radius: 4px;
}
.Order_top_inner3{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 4px;
  background: #FBE9B3;
  padding: 12px 12px 12px 7px;
  border-radius: 4px;
}
.Order_top_inner4{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 4px;
  background: #acabaa;
  padding: 12px 12px 12px 7px;
  border-radius: 4px;
}
.Order_top_inner5{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 4px;
  background: hsl(189, 90%, 58%);
  padding: 12px 12px 12px 7px;
  border-radius: 4px;
}

.custom_tab_class{
 background: #FFFFFF;
 box-shadow: 0px 1px 0px rgba(41, 41, 41, 0.1);
 width: 100%;
 gap: 0 20px;
}
.order_link{
  padding: 13px 0 11px 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #17263A;
}
.now_order_length{
  background: #B01E3C;
  border-radius:3px;
  position: absolute;
  width: 18px;
  height: 18px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  margin-left: 5px;
  margin-top: -4px;
  padding-top: 2px;
}
.now_order_length1{
  background: rgba(0, 170, 251, 0.1);
  border-radius:3px;
  position: absolute;
  width: 17px;
  height: 16px;
  font-weight: 400;
  font-size: 9px;
  line-height: 14px;
  color: #17263A;
  margin-left: 5px;
  margin-top: -4px;
}
.order_tab_active {
  border-bottom: 4px solid #00AAFB;
  background-color: #ffffff;
}
.custom_tab_botton{
  width: 7%;
}
.custom_tab_botton:hover{
  border-bottom: 4px solid #00AAFB;
  background-color: #ffffff;
  color: #000000;
}
.custom_tab_botton:focus{
  outline: none;
}
.tab_top_class{
  display:flex;
  align-items: stretch;
}
.tab_top_class_inner{
  width: 320px;
  background: #EFF4F9;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);
}
.tab_top_class_inner_history{
  width: 320px;
  background: #EFF4F9;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 73px);
}

.tab_top_class_inner_banner{
   height: calc(100vh - 48px);
}
.custom_search_input{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  background: #EFF4F9;
  padding-left: 20px;
}
.tab_top_input_box{
  padding-top: 1px;
  height: 52px;
  min-height: 52px;
}
.tab_top_input_box svg{
  position: absolute;
  right: 0;
  margin-right: 21px;
}
.new_page_inner_inside{
  display: flex;
  background: #DC3545;
  padding: 13px 20px 14px 15px;
  border: 1px solid #C4C4C4;
  justify-content: space-between;
  align-items: center;
}
.new_page_inner_text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 21px;
  color: #FFFFFF;
  padding-bottom: 5px;
}
.new_page_inner_text1{ 
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: #FFFFFF;
}
.new_page_inner_text1 span{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.new_page_inner2{
  text-align: center;
  background: #ffffff;
  padding: 9px 5px 9px 5px;
  border: 2px solid #000000;
  box-sizing: border-box;
  width: 77px;
}
.new_page_inner_h1{
 font-family: 'Inter';
 font-style: normal;
 font-weight: 400;
 font-size: 18px;
 line-height: 18px;
 color: #292929;
 padding-bottom: 4px;
}
.new_page_inner_p{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #292929;
}
.new_page_inner{
  margin-bottom: 10px;
}
.order_detail_page{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.orderDetailWrapper {
  height: calc(100vh - 220px);
  overflow: hidden;
  overflow-y: auto;
}
.historyDetailWrapper{
  height: calc(100vh - 173px);
  overflow: hidden;
  overflow-y: auto;
}
.orderDetailWrapper_banner {
  height: calc(100vh - 148px);
  overflow: hidden;
  overflow-y: auto;
}
.orderDetailWrapper::-webkit-scrollbar {
  display: none;
}
.order_product_detail_wrapper{
  height: calc(100vh - 250px);
  overflow: hidden;
  overflow-y: scroll;
}
.order_product_detail_wrapper::-webkit-scrollbar {
  display: none;
}
.order_detail_page_top{
   width: 73%;
}
.order_refresh_tab{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #435673;
  padding: 10px 0 12px 0;
  border: 1px solid #435673;
  border-radius: 4px;
  gap: 0 7px;
  margin: auto 0 0 0;
}


.preparing_page_inner_inside{
  display: flex;
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  padding: 13px 20px 14px 15px;
  justify-content: space-between;
  align-items: center;
}
.prepare_inner_text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 21px;
  color: #000000;
  padding-bottom: 5px;
}
.prepare_inner_text1{ 
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: #000000;
}
.prepare_inner_text1 span{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.prepare_inner{
  margin-bottom: 5px;
}
.prepare_inner_h1{
 font-family: 'Inter';
 font-style: normal;
 font-weight: 400;
 font-size: 18px;
 line-height: 18px;
 color: #292929;
 padding-bottom: 4px;
}
.prepare_inner_p{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #292929;
}


.order_detail_top{
  margin-left: 112px;
}

.order_detail_page_inner{
  display: flex;
  gap: 0 25px;
}
.order_detail_inner1{
  flex: 1;
}
.order_detail_inner2{
  flex: 1;
  padding-top: 18px;
}
.order_detail_heading_nav{
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
}
.order_detail_heading_2nav{
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
}
.order_detail_heading_nav3{
  padding-left: 48px;
}
.order_detail_heading_top{
  display: flex;
  padding: 13px 0 8px 0;
  border-bottom: 1px solid #E8E9EA;
}
.order_detail_heading_nav1{
  padding-left: 40px;
}
.order_detail_heading_nav2{
  padding-right: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #444444;
}
.order_detail_heading_nav1_p{  
  border: 1px solid #343A40;
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #444444;
  padding: 8px;
}
.order_detail_heading_nav3_link1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 11px;
  color: #FFFFFF;
  background: #F44336;
  border-radius: 4px;
  padding: 6px;
}
.order_detail_heading_nav3_link2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 11px;
  color: #FFFFFF;
  background: #4FB678;
  border-radius: 4px;
  padding: 6px;  
}
.order_detail_heading_nav4{
  display: flex;
  align-items: center;
  gap: 0 9px;
  padding-right: 34px;
}
.order_summary{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #9292AC;
  padding-left: 14px;
  padding-top: 10px;
}
.order_summary_inner{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 14px;
  border-bottom: 1px solid #EAEFF2;
  padding-bottom: 4px;
}
.order_summary_inner_text{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #0F1B1A;
}
.order_summary_inner1{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #0F1B1A;
  display: flex;
  align-items: center;
  gap: 0 6px;
}

.order_detail_product{
  padding-left: 16px;
  border-bottom: 1px solid #EAEFF2;
  padding-bottom: 15px;
}
.order_detail_product_inner{
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}
.choice_of_size{
  padding-left:24px;
  padding-top: 15px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #9292AC;
}
.choice_of_size_regular{
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  padding-top: 5px;
}
.order_detail_product_inner1_p{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #0F1B1A;
}
.order_detail_product_inner2{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #0F1B1A;
}
.order_detail_product_inner1_span{
  color: #9292AC;
  font-weight: 600;
}

.order_detail_add_ons{
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 0 9px;
  background: #F3F7F9;
  border-radius: 4px;
  padding: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}
.order_detail_page_price{
  padding-left: 16px;
  padding-bottom: 15px;
}
.order_subtotal{
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.order_subtotal p{
 font-family: 'Roboto';
 font-style: normal;
 font-weight: 400;
 font-size: 16px;
 line-height: 19px;
 color: #9292AC;
}
.order_delivery{
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.order_delivery p{
 font-family: 'Roboto';
 font-style: normal;
 font-weight: 400;
 font-size: 16px;
 line-height: 19px;
 color: #9292AC;
}
.order_promo{
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.order_promo p{
 font-family: 'Roboto';
 font-style: normal;
 font-weight: 400;
 font-size: 16px;
 line-height: 19px;
 color: #9292AC;
}
.order_total{
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.order_total p{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #0F1B1A;
}
.order_price_top{
  border-bottom: 1px solid #EAEFF2;
  padding-top: 15px;
}


.order_detail_inner2_p_1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #89A0B1;
  padding-bottom: 6px;
}
.order_detail_inner2_p_2{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #4FB678;
}
.order_detail_customer{
  display: flex;
  justify-content: space-between;
  padding-right: 35px;
  padding-top: 13px;
}

.order_detail_customer_p1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #89A0B1;
}
.order_detail_customer_p2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #444444;
}

.order_detail_customer_p3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ECAF06;
}
.order_detail_customer_p4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
 color: #89A0B1;
}
.order_detail_customer_p5{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #444444;
}
.order_phone_icon{
  display: flex;
  align-items: center;
  gap: 0 12px;
}

.order_detail_address{
  display: flex;
  gap: 0 0;
  padding-top: 14px;
}
.order_address_icon{
  display: flex;
  align-items: center;
  gap: 0 8px;
}
.order_detail_address_p1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #89A0B1;
}
.order_detail_address_h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #444444;
}
.order_detail_address_p2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #444444;
}
.order_detail_address_p3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #444444;  
}
.order_detail_address_p4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #89A0B1;
}
.order_detail_address_p5{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #444444;
}
.order_detail_driver{
  display: flex;
  gap: 0 53px;
  padding-top: 21px;
}
.order_driver_border{
  border-bottom: 1px solid #E8E9EA;
  padding-top:17px ;
}
.order_driver_detail_p1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #4FB678;
}
.order_driver_detail_p2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #4FB678;
}
.order_driver_detail_name{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* text-decoration-line: underline; */
  color: #444444;
}
.order_driver_detail_p3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #444444;
}
.driver_icon{
  display: flex;
  align-items: center;
  gap: 0 8px;
}
.driver_botton{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
}
.driver_botton1{
  background: #B01E3C;
  border: 1px solid #F44336;
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 13px;
  padding: 15px 55px 15px 55px;
  color: #ffffff;
}
.driver_botton2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 12px;
  color: #343A40;
  padding: 15px 51px 15px 51px;
  border: 1px solid #343A40;
  border-radius: 4px;
  margin-top: 11px;
}

.order_detail_later_heading_top{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order_detail_later_top{
  padding: 9px 0 8px 0;
  border-bottom: 1px solid #E8E9EA;
  padding-left: 40px;
  padding-right: 40px;
}
.order_detail_later_schedule{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #B01E3C;
}
.order_driver_border_later{
  border-bottom: 1px solid #E8E9EA;
  padding-top:77px ;  
}
.order_driver_later_2{
  padding-top: 50px;
}
.later_head_top{
  display: flex;
  gap: 0 10px;
  background: #913A0F;
  padding: 8px 0 8px 5px;
}
.later_head_top_inner{
  padding: 0 20px 0 20px;
}
.later_head_inner_text_h3{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  display: flex;
  color: #FFFFFF;
  padding-bottom: 6px;
}
.later_head_inner_text_p{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  color: #FFFFFF;
  width: 69%;
}

.order_cancel_button{
  padding-top:20px ;
  gap: 0 23px;
}
.custom_inset{
  top: 0px;
  right: 51px;
}
.custom_bg{
  background: #89A0B1;
}

.order_cancel_button1{
  gap: 0 8px;
  padding: 8px 0;
}
.order_cancel_button_close{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #343A40;
  padding: 12px 72px 12px 72px;
  border: 1px solid #343A40;
  border-radius: 4px;
}
.order_cancel_button_cancel{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #DC3545;
  padding: 12px 72px 12px 72px;
  border: 1px solid #DC3545;
  border-radius: 4px;
}
.order_cancel_button_complete{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  text-transform: uppercase;
  color: #FFFFFF;
  border: 1px solid #0084FF;
  border-radius: 4px;
  padding: 12px 100px 12px 100px;
  background: #0084FF;
}
.custom_bg1{
  background: #f2f2f2;
}
.order_detail_address_inner1{
  width: 98%;
}
.order_later_customer{
  padding-top: 28px;
  justify-content: space-between;
  padding-right: 35px;
  display: flex;
}

.settings_heading_top_bg {
  background: #F3F7F9;
  height: calc(100vh - 153px);
  overflow: hidden;
  overflow-y: auto;
}
.settings_heading_top_bg::-webkit-scrollbar {
  display: none;
}
.setting_button_1{
  --tw-translate-y: -0.2rem !important;
}
.bg_switch_setting_click{
  background: #F1F1F1;
}
.switch_setting_click{
  background: #B01E3C;
}
.settings_heading_top_bg{
  background: #F3F7F9;
}
.settings_heading_top{
  display: flex;
  justify-content: space-between;
  background: #fff;
  margin-top: 10px;
  padding: 0 38px 0 12px;
}
.settings_heading_top_inner{
  width: 70%;
  padding: 10px 1px 20px 18px;

}
.settings_heading_top_text1{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #0F1B1A;
}
.settings_heading_top_text2{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #55565F;
  padding-top: 5px;
}
.bg-switch_click{
  background: rgba(34, 31, 31, 0.26);
}
.bg-switch_not_click{
  background: rgb(176,30,60, 0.6);
}
.menu_heading{
  background: #B01E3C;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  padding: 20px 0 20px 0;
  height: 65px;
}
.menu_heading p{
 color: #fff;
 font-family: Roboto;
 font-weight: 500;
 font-size: 20px;
 line-height: 23.44px;
}
.menu_heading_top{
  background: #F3F7F9;
  height: calc(100vh - 153px);
  overflow: hidden;
  overflow-y: auto;
}
.menu_heading_topmenu{
  background: #F3F7F9;
  height: calc(100vh - 100px);
  overflow: hidden;
  overflow-y: auto;
}
.menu_heading_top::-webkit-scrollbar {
  display: none;
}
.toggle_when_open{
  background: #F3F7F9;
}
.toggle_when_not_open{
  color: black;
}
.menu_heading_top_1{
  padding: 20px;
}
.button_1{
  color: #fff;
  padding: 8px;
  border: 1px solid #B01E3C;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  background:#A00C2A;
}
.button_2{
  color:#9292AC;
  border: 1px solid #EAEAEA;
  background: #fff;
  box-sizing: border-box;
  padding: 8px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height:16px;
  border-radius: 6px;
  margin-left: 16px;
}
.menu_inner_1{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
}
.menu_inner_2{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #9292AC;
}
.menu_inner_part_1{
  display: flex;
  justify-content: space-between;
}
.menu_inner_part_1_inner{
  display: flex;
}
.menu_inner_part_1_inner_text1{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 28px;
  color: #FFFFFF;
  padding: 0px 8px 0px 8px;
  border-radius: 2px;
  background: #B3B3C8;
}
.menu_inner_part_1_inner_text2{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
}
.menu_inner_part_1_inner_text3{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #9292AC;
}
.menu_inner_part_2{
  display: flex;
  justify-content: space-between;
}
.menu_li_bottom_margin{
  margin-bottom: 5px;
}
.menu_tab_botton:focus{
  outline: none;
}
.menu_tab_class{
  background: #FFFFFF;
  width: 100%;
  gap: 0 20px;
  padding: 15px 0 15px 20px;
}
.header_top_inner.active .header_image_1 path {
  fill:#ffffff;
  opacity: 1;
}
.bg_color_open{
  background: #F3F7F9;
}
.menu_li_bottom_margin1{
  margin-bottom: 2px;
}



@media screen and (max-width:768px) {
  .Order_top_inner {
    flex-direction: column;
    padding:0 0 0 0;
    width: 100%;
  }
  .header_top {
    position: initial;
    width: 100%;
    padding: 5px 12px;
  }
  .header_nav {
    flex-direction: row;
    justify-content: space-between;
  }
  .header_inner_1 {
    padding: 0;
  }
  .header_inner_1 a img {
    height: 60px;
  }
  .Order_top_class {
    margin-left:0;
  }
  .Order_top_inner1 {
    width: 100%;
  }
  .Order_top_inner2 {
    width: 100%;
  }
  .Order_top_inner3 {
    width: 100%;
  }
  .custom_tab_botton {
    width: 100%;
  }
  .tab_top_class {
    flex-direction: column;
  }
  .tab_top_class_inner {
    width:100%;
    /*height: calc(100vh - 222px);*/
    height: auto;
  }
  .order_detail_page_top {
    width: 100%;
    padding: 0 0 70px;
  }
  .order_detail_heading_nav1 {
    padding-left: 0px;
  }
  .order_detail_heading_nav2 {
    padding-right: 0px;
  }
  .order_detail_heading_nav3 {
    padding-left: 0px;
  }
  .order_detail_heading_nav4 {
    padding-right: 0px;
  }
  .order_detail_heading_top {
    gap: 0 20px;
    margin: 0 5px 0 5px;
  }
  .order_detail_heading_nav {
    width: auto;
    gap: 0 20px;
  }
  .order_detail_heading_2nav {
    width: auto;
    gap: 0 20px;
  }
  .order_detail_page_inner {
    flex-direction: column;
    gap: 0 0;
  }
  .order_summary {
    padding-left: 14px;
  }
  .order_summary_inner {
    padding: 0 14px 0 14px;
  }
  .order_detail_product {
    padding: 0 16px 15px 16px;
  }
  .order_detail_page_price {
    padding: 0 16px 0 16px;
  }
  .order_detail_customer {
    padding-right: 0px;
  }
  .order_detail_address {
    justify-content: space-between;
  }
  .order_detail_inner2 {
    margin: 0 16px 0 16px;
  }
  .driver_botton {
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;
    gap: 0 9px;
  }
  .driver_botton2 {
    margin-top: 0px;
    padding: 15px 51px 15px 51px;
  }

  .new_page_inner_inside {
    padding: 5px 12px 5px 10px;
  }

  .new_page_inner_text {
    font-size: 20px;
  }

  .new_page_inner2 {
    padding: 5px 10px 5px 10px;
  }
  .driver_botton1 {
    padding: 15px 40px 15px 40px;
  }
  .order_detail_heading_nav1_p {
    font-size: 17px;
  }
  .order_detail_heading_nav2 {
    font-size: 16px;
  }
  .order_detail_heading_nav3_link2 {
    font-size: 18px;
  }

  .orderDetailWrapper {
    height: unset;
    max-height: 205px;
    margin: 0 0 30px;
  }

  .order_refresh_tab {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
  }
  .preparing_page_inner_inside {
    padding: 5px 12px 5px 10px;
  }
  .prepare_inner_text {
    font-size: 20px;
  }
  .prepare_inner_h1 {
    font-size: 20px;
  }
  .order_detail_page {
    padding-top: 12px;
  }
  .custom_inset {
    right: 0;
    left: 0;
  }
  .custom_tab_class {
    gap: 0 10px;
  }
  .order_product_detail_wrapper {
    height: calc(100vh - 360px);
  }
  .order_driver_border_later {
    padding-top: 34px;
  }
}
